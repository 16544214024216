const Blog = {
    init() {
        const $list = $("#collapse-list");
        const listItemHeight = $('.list-group-item').outerHeight();
        const listLength = $list.find('li').length;
        const $toggleCollapse = $('#toggle-collapse');

        if ($list.height() > 100) {
            $list.addClass("expand closed");
        }

        $list.css("height", "auto");
        const listHeight = $list.css("height");

        if (listLength > 5) {
            $list.css("height", listItemHeight * 5 + 'px');
        } else {
            $list.addClass('uq-bordered');
            $toggleCollapse.addClass('d-none');
        }

        $toggleCollapse.on("click", function () {
            $toggleCollapse.toggleClass('open');
            $list.toggleClass("open closed");

            if ($list.hasClass("open")) {
                $list.height(listHeight);
            }
            if ($list.hasClass("closed")) {
                $list.height(listItemHeight * 5);
            }
        });
    }
};

export { Blog };
export default Blog;
