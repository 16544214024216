window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap/js/src/base-component');
window.bootstrap = require('bootstrap/js/src/dropdown');
window.bootstrap = require('bootstrap/js/src/tab');
window.bootstrap = require('bootstrap/js/src/button');
window.bootstrap = require('bootstrap/js/src/collapse');
window.bootstrap = require('bootstrap/js/src/offcanvas');

require('@popperjs/core');

import { Header } from './components/Header';
import { Aos } from './components/AOS';
import { Preloader } from './components/Preloader';
import { Slider } from './components/Slider';
import { Blog } from './components/Blog';
import { Map } from './components/Map';
import { Counter } from './components/Counter';

$(function () {
  'use strict';

  const $main = $('main'),
    $header = $('#top'),
    $headerTopNav = $('#top-nav'),
    $footer = $('#footer'),
    windowHeight = window.innerHeight,
    $404Page = $('.uq-heading-404');

  const ARES = {
    init() {
      Header.init();
      Slider.init();
      Blog.init();
      Map.init();
      Counter.init();
    },
    onLoad() {
      Aos.init();
      Aos.refresh();
      this.initPage();
      Preloader.init();
    },
    onResize() {
      this.initPage();
      Slider.update();
      Header.update();
    },
    initPage() {
      const outerHeight = $headerTopNav.outerHeight() || 0;

      if (windowHeight > $main.outerHeight()) {
        $main.css('min-height', windowHeight - $header.outerHeight() - $footer.outerHeight() - outerHeight);
      }

      if ($404Page.length > 0) {
        $404Page.css('min-height', windowHeight - $header.outerHeight() - $footer.outerHeight() - outerHeight);
      }
    },
  };

  ARES.init();
  ARES.onLoad();

  console.log('Document is ready!!');
  console.log('%cMade with ♥ by Uniqoders - https://uniqoders.com', 'color:#403c5e; font-size:36px');

  $(window).on('resize', () => {
    ARES.onResize();
  });

  setTimeout(() => {
    Header.update();
  }, 500);
});
