import AOS from 'aos/dist/aos';

const Aos = {
  init() {
    if (!AOS) return;

    AOS.init({
      startEvent: 'DOMContentLoaded',
      once: true,
      duration: 600,
      offset: 0,
      disable: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
    });
  },
  refresh() {
    if (!AOS) return;

    setTimeout(() => {
      AOS.refresh();
    }, 100);
  },
};

export { Aos };
export default Aos;
