const Preloader = {
  selectors: '[data-preloader="load"]',
  init() {
    const $preloader = $(this.selectors);

    if ($preloader.length > 0) {
      const delay = $preloader.attr('data-delay') || 0;

      setTimeout(() => {
        $('body').addClass('loaded');
        $preloader.addClass('loaded');
      }, delay);
    }
  }
};

export { Preloader };
export default Preloader;
