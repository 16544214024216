const Map = {
    init: function () {
        //TODO Prepare for both leaflet and gmaps implementation
        const maps = document.getElementsByClassName('md-map-element');

        if (maps.length > 0) {
            const L = require('leaflet');

            for (let map of maps) {
                let lat = parseFloat(map.dataset.lat);
                let lng = parseFloat(map.dataset.lng);
                let zoom = parseFloat(map.dataset.zoom);
                let pointers = JSON.parse(map.dataset.pointers);
                let image = map.dataset.image;
                let icon;

                if (!!image) {
                    icon = image;
                } else {
                    icon = 'https://cheatsheet.uniqoderslab.com/wp-content/uploads/2021/10/map-icon.png';
                }

                let map_icon = L.icon({
                    iconUrl: icon,
                    iconSize: [50, 50],
                    shadowSize: [64, 64],
                    iconAnchor: [25, 25],
                    popupAnchor: [-3, -76]
                });

                let mapObject = L.map(map).setView([lat, lng], zoom);

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(mapObject);

                if (pointers.length > 0) {
                    for (let point in pointers) {
                        let pointerLat = parseFloat(pointers[point]['lat']);
                        let pointerLng = parseFloat(pointers[point]['lng']);

                        L.marker([pointerLat, pointerLng], {icon: map_icon}).addTo(mapObject);
                    }
                } else {
                    L.marker([lat, lng], {icon: map_icon}).addTo(mapObject);
                }
            }
        }
    }
};

export { Map };
export default Map;
