const Header = {
  init() {
    const $html = $('html');
    const $body = $('body');
    const $header = $('#top');
    const $headerToggler = $('[data-bs-toggle="collapse"].hamburger');
    const headerHeight = $header.innerHeight();
    const isOverlapping = $header.hasClass('header-overlap');
    const headerSticky = ['sticky', 'sticky-downsize'].includes($header.attr('data-sticky'));
    const $headerTopNav = $('#md-top-layout');

    if ($headerToggler.length > 0) {
      $headerToggler.on('click', function () {
        const $this = $(this);
        const isActive = $this.hasClass('is-active');

        if (!isActive) {
          $this.addClass('is-active');

          if ($(window).width() < 992) {
            $headerTopNav.toggleClass('close');
            $body.addClass('menu-open');
            $html.addClass('menu-open');
          }
        } else {
          $this.removeClass('is-active');
          $body.removeClass('menu-open');
          $html.removeClass('menu-open');
          $headerTopNav.toggleClass('close');
        }
      });
    }

    if ($header.length > 0 && headerSticky) {
      let position = $body.offset().top + headerHeight;
      let totalHeaderHeight = headerHeight;

      if ($headerTopNav.length > 0) {
        position += $headerTopNav.find('> div').innerHeight();
        totalHeaderHeight += $headerTopNav.find('> div').innerHeight();
      }

      const handleScroll = () => {
        const shouldStick = $(document).scrollTop() > position;

        $header.toggleClass('sticky', shouldStick);
        $html.toggleClass('sticky', shouldStick);

        if (headerSticky && isOverlapping) {
          $body.css('padding-top', shouldStick ? -totalHeaderHeight : 0);
        } else {
          $body.css('padding-top', shouldStick ? totalHeaderHeight : 0);
        }
      };

      handleScroll();
      $(window).scroll(handleScroll);
    }

    if ($headerTopNav.length > 0) {
      $body.css('--md-top-nav-h', `${$headerTopNav.find('> div').innerHeight()}px`);
    }

    $('.navbar-nav .menu-item.menu-item-has-children.dropdown .dropdown-menu .menu-item-has-children.dropdown > .dropdown-toggle').on('click', function (e) {
      e.stopPropagation();
    });
  },
  update() {
    const $body = $('body');
    const $headerTopNav = $('#md-top-layout');

    if ($headerTopNav.length > 0) {
      $body.css('--md-top-nav-h', `${$headerTopNav.find('> div').innerHeight()}px`);
    }
  }
};

export { Header };
export default Header;
